// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---content-posts-blog-10-years-index-mdx": () => import("./../../../content/posts/blog/10-years/index.mdx" /* webpackChunkName: "component---content-posts-blog-10-years-index-mdx" */),
  "component---content-posts-blog-3-code-tools-for-building-your-idea-index-mdx": () => import("./../../../content/posts/blog/3-code-tools-for-building-your-idea/index.mdx" /* webpackChunkName: "component---content-posts-blog-3-code-tools-for-building-your-idea-index-mdx" */),
  "component---content-posts-blog-3-things-to-do-before-changing-your-job-index-mdx": () => import("./../../../content/posts/blog/3-things-to-do-before-changing-your-job/index.mdx" /* webpackChunkName: "component---content-posts-blog-3-things-to-do-before-changing-your-job-index-mdx" */),
  "component---content-posts-blog-3-things-you-can-do-to-improve-your-life-index-mdx": () => import("./../../../content/posts/blog/3-things-you-can-do-to-improve-your-life/index.mdx" /* webpackChunkName: "component---content-posts-blog-3-things-you-can-do-to-improve-your-life-index-mdx" */),
  "component---content-posts-blog-4-selfdiscipline-hacks-to-hack-your-life-index-mdx": () => import("./../../../content/posts/blog/4-selfdiscipline-hacks-to-hack-your-life/index.mdx" /* webpackChunkName: "component---content-posts-blog-4-selfdiscipline-hacks-to-hack-your-life-index-mdx" */),
  "component---content-posts-blog-be-in-control-of-yourself-index-mdx": () => import("./../../../content/posts/blog/be-in-control-of-yourself/index.mdx" /* webpackChunkName: "component---content-posts-blog-be-in-control-of-yourself-index-mdx" */),
  "component---content-posts-blog-css-trick-you-did-not-know-about-index-mdx": () => import("./../../../content/posts/blog/css-trick-you-did-not-know-about/index.mdx" /* webpackChunkName: "component---content-posts-blog-css-trick-you-did-not-know-about-index-mdx" */),
  "component---content-posts-blog-fresh-start-index-mdx": () => import("./../../../content/posts/blog/fresh-start/index.mdx" /* webpackChunkName: "component---content-posts-blog-fresh-start-index-mdx" */),
  "component---content-posts-blog-gitlens-visualize-project-history-index-mdx": () => import("./../../../content/posts/blog/gitlens-visualize-project-history/index.mdx" /* webpackChunkName: "component---content-posts-blog-gitlens-visualize-project-history-index-mdx" */),
  "component---content-posts-blog-happy-birthday-to-me-index-mdx": () => import("./../../../content/posts/blog/happy-birthday-to-me/index.mdx" /* webpackChunkName: "component---content-posts-blog-happy-birthday-to-me-index-mdx" */),
  "component---content-posts-blog-how-are-you-doing-in-covid-time-index-mdx": () => import("./../../../content/posts/blog/how-are-you-doing-in-covid-time/index.mdx" /* webpackChunkName: "component---content-posts-blog-how-are-you-doing-in-covid-time-index-mdx" */),
  "component---content-posts-blog-how-nimi-built-their-startup-with-no-coding-skills-index-mdx": () => import("./../../../content/posts/blog/how-nimi-built-their-startup-with-no-coding-skills/index.mdx" /* webpackChunkName: "component---content-posts-blog-how-nimi-built-their-startup-with-no-coding-skills-index-mdx" */),
  "component---content-posts-blog-how-to-display-size-imported-packages-index-mdx": () => import("./../../../content/posts/blog/how-to-display-size-imported-packages/index.mdx" /* webpackChunkName: "component---content-posts-blog-how-to-display-size-imported-packages-index-mdx" */),
  "component---content-posts-blog-how-to-find-your-next-project-index-mdx": () => import("./../../../content/posts/blog/how-to-find-your-next-project/index.mdx" /* webpackChunkName: "component---content-posts-blog-how-to-find-your-next-project-index-mdx" */),
  "component---content-posts-blog-how-to-start-your-next-community-index-mdx": () => import("./../../../content/posts/blog/how-to-start-your-next-community/index.mdx" /* webpackChunkName: "component---content-posts-blog-how-to-start-your-next-community-index-mdx" */),
  "component---content-posts-blog-how-to-support-junior-developers-index-mdx": () => import("./../../../content/posts/blog/how-to-support-junior-developers/index.mdx" /* webpackChunkName: "component---content-posts-blog-how-to-support-junior-developers-index-mdx" */),
  "component---content-posts-blog-how-to-take-pixel-perfect-screenshots-index-mdx": () => import("./../../../content/posts/blog/how-to-take-pixel-perfect-screenshots/index.mdx" /* webpackChunkName: "component---content-posts-blog-how-to-take-pixel-perfect-screenshots-index-mdx" */),
  "component---content-posts-blog-interview-with-rubrain-index-mdx": () => import("./../../../content/posts/blog/interview-with-rubrain/index.mdx" /* webpackChunkName: "component---content-posts-blog-interview-with-rubrain-index-mdx" */),
  "component---content-posts-blog-no-quick-path-index-mdx": () => import("./../../../content/posts/blog/no-quick-path/index.mdx" /* webpackChunkName: "component---content-posts-blog-no-quick-path-index-mdx" */),
  "component---content-posts-blog-okr-index-mdx": () => import("./../../../content/posts/blog/okr/index.mdx" /* webpackChunkName: "component---content-posts-blog-okr-index-mdx" */),
  "component---content-posts-blog-provide-value-as-a-founder-index-mdx": () => import("./../../../content/posts/blog/provide-value-as-a-founder/index.mdx" /* webpackChunkName: "component---content-posts-blog-provide-value-as-a-founder-index-mdx" */),
  "component---content-posts-blog-quokka-livescratchpad-for-javascript-index-mdx": () => import("./../../../content/posts/blog/quokka-livescratchpad-for-javascript/index.mdx" /* webpackChunkName: "component---content-posts-blog-quokka-livescratchpad-for-javascript-index-mdx" */),
  "component---content-posts-blog-software-developer-without-degree-index-mdx": () => import("./../../../content/posts/blog/software-developer-without-degree/index.mdx" /* webpackChunkName: "component---content-posts-blog-software-developer-without-degree-index-mdx" */),
  "component---content-posts-blog-strength-love-happiness-index-mdx": () => import("./../../../content/posts/blog/strength-love-happiness/index.mdx" /* webpackChunkName: "component---content-posts-blog-strength-love-happiness-index-mdx" */),
  "component---content-posts-blog-vscode-extension-for-taking-screenshots-index-mdx": () => import("./../../../content/posts/blog/vscode-extension-for-taking-screenshots/index.mdx" /* webpackChunkName: "component---content-posts-blog-vscode-extension-for-taking-screenshots-index-mdx" */),
  "component---content-posts-blog-vscode-extension-rest-client-index-mdx": () => import("./../../../content/posts/blog/vscode-extension-rest-client/index.mdx" /* webpackChunkName: "component---content-posts-blog-vscode-extension-rest-client-index-mdx" */),
  "component---content-posts-blog-which-programming-language-should-i-learn-first-index-mdx": () => import("./../../../content/posts/blog/which-programming-language-should-i-learn-first/index.mdx" /* webpackChunkName: "component---content-posts-blog-which-programming-language-should-i-learn-first-index-mdx" */),
  "component---content-posts-blog-working-anytime-anywhere-index-mdx": () => import("./../../../content/posts/blog/working-anytime-anywhere/index.mdx" /* webpackChunkName: "component---content-posts-blog-working-anytime-anywhere-index-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-community-builder-js": () => import("./../../../src/pages/community_builder.js" /* webpackChunkName: "component---src-pages-community-builder-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-creator-js": () => import("./../../../src/pages/creator.js" /* webpackChunkName: "component---src-pages-creator-js" */),
  "component---src-pages-cv-js": () => import("./../../../src/pages/cv.js" /* webpackChunkName: "component---src-pages-cv-js" */),
  "component---src-pages-developer-js": () => import("./../../../src/pages/developer.js" /* webpackChunkName: "component---src-pages-developer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leadership-js": () => import("./../../../src/pages/leadership.js" /* webpackChunkName: "component---src-pages-leadership-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy_policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-projects-project-template-js": () => import("./../../../src/pages/projects/project-template.js" /* webpackChunkName: "component---src-pages-projects-project-template-js" */),
  "component---src-pages-social-media-index-js": () => import("./../../../src/pages/social-media/index.js" /* webpackChunkName: "component---src-pages-social-media-index-js" */)
}

